.App{
  font-family:'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body{
  background-color: #C7EFCF
}

.Header__container--all{
  background-color: #C7EFCF ;
  margin-bottom: 2%;
}

.Header__title--link{
  text-decoration: none;
}

.Header__title{
  text-align: center;
  color: #495d4d;
  padding-top: 2%;
}

.Header__container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.Header__users--label{
  text-align: center;
}

.Users__button--link-to-users{
    background-color: #F0B67F;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 7%;
    border: 2px solid grey
  }

  .Nav__button--parent{
    text-align: center;
    }
  
  button.Nav__button--child{
    background-color: #F0B67F;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 7%;
    border: 2px solid grey;
    margin-left:5px;
    margin-right:5px
  }

  
  .Sort-by__container {
    text-align: center;
    padding-top: 2%;
  }

ul.Article__ul--list-item-parent {
  padding: inherit;
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
  margin-top: 0;
  background-color: #EEF5DB;
}

.Article__list-item-card--link{
  text-decoration: none;
}

.Article__list-item-card{
  color: #495d4d;
  list-style: none;
  border: 1px solid grey;
  padding: 0 auto;
}

.Article-Page__div--parent{
  padding-top: 5px;
  padding-bottom:5px;
  margin-left: 10%;
  margin-right:10%;
  background-color: #EEF5DB;
  color:#495d4d;
  
}
ul.Topic__list-item-parent {
  padding: inherit;
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
  margin-top: 0;
  background-color: #EEF5DB;
}

.Article-Page__div--parent{
  text-align: center
  }

.Article-Page__body{
  margin: 0 10%;
  line-height: 1.6;
}

.Article-Page__title{
  font-style: oblique;
}

.Article-Page__topic{
  text-transform:capitalize;
  text-decoration: underline;
}


.Article-Page__vote-count--button-container{
  display:flex;
  justify-content: center;
  gap: 10px;
}

.Article-Page__vote-count-button--upvote{
  background-color: blue; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6%;
}

.Article-Page__vote-count-button--downvote{
  background-color: red; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6%;
}

ul.Comments__ul--parent {
  list-style: none;
  padding: 0;
}

.Comments__li{
  border: 1px solid grey;
  margin-left: 10%;
  margin-right: 10%;
}

.Comments__button{
  background-color: #F0B67F; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6%;
}

form.Comment__input-form--form_box {
  padding-top: 3%;
}

input.Comment__input-form--submit-button {
  background-color: green; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6%;
}

label.Comment__input-form--label {
  display: block;
}

button.DeleteComments__button {
  background-color: red; 
  border: none;
  color: white;
  padding-left: 10px;
  padding-right:10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6%;
}


.Users__buttons--content {
  display: flex;
  justify-content: space-evenly;
  background-color: #EEF5DB;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-left: 10%;
  margin-right:10%;
  border: 2px solid #495d4d;
  gap:5px;
}

button.Users__buttons--button-style {
  background-color: #F0B67F; 
  border: 1px solid #495d4d;
  color: white;
  padding-left: 10px;
  padding-right:10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6%;
}

.Users__button--username-text{
  text-align: center;
}

.Users__button--image{
  max-width: 50px;
  aspect-ratio: 1/1;
}

.Users__div--button{
  text-align: center;
}

.Error-Handler__div--parent{
  background-color: #EEF5DB;
  text-align: center;
  color: #495d4d;
}

.Error-Handler__heading{
  padding-top: 2%;
}

.Error-handler__image{
  max-width: 60%;
  max-height: 60%;
}

.Error-Handler__link{
  color:#495d4d;
}


@media (max-width: 600px){
  .Article__list-item-card {
    text-align: center;
    border:1px solid grey;
  }
  ul{
    padding: 0;
  }
}






